<template>
  <v-app>
    <dashboard-core-app-bar v-model="expandOnHover" />


    <dashboard-core-view />
  </v-app>
</template>

<script>
  export default {
    name: 'GeneticProfileIndex',

    components: {
      DashboardCoreAppBar: () => import('../components/core/AppBar'),
      //DashboardCoreDrawer: () => import('./components/core/Drawer'),
      DashboardCoreView: () => import('../components/core/View'),
    },

    data: () => ({
      expandOnHover: false,
    }),
  }
</script>
